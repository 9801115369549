<template>
    <div>
      <section class="" style="padding:1rem 1.5rem">
        <div class="information-text has-text-centered">
          <h1 class="heading-title is-uppercase has-text-primary has-text-weight-bold addMarginBottom-1">Nos bateaux skippers</h1>
          <Separator class="image is-48x48" color="primary"/>
        </div>
        <div class="container" style="overflow:hidden">
          <div ref="thisCarousel" id="carouselCTA" class="carousel">
            <div style="margin:0 10px" :class="[`item-${(index + 1)}`]" v-for="(imageCarousel, index) in imagesCarousel" :key="'image-carousel-'+index">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img :src="imageCarousel.thumb" alt="JeStockeMonBateau.fr - Nos bateaux skippers">
                  </figure>
                </div>
                 <div class="card-content">
                  <div class="content">
                    <em>{{ imageCarousel.text}}</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style="padding-bottom:0">
        <div class="has-text-centered">
          <p>Des milliers de plaisanciers sont à la recherche d’un emplacement pour leur bateau</p>
          <strong class="is-uppercase has-text-primary">Proposez le votre et gagnez de l’argent facilement !</strong>
        </div>
        <figure class="autoHome">
          <img :src="require('./../../../assets/illustrationAnnonces-hd.png')" style="display:block" alt="JeStockeMonBateau.fr - Des emplacements pour votre bateau">
        </figure>
      </section>

    </div>
</template>
<script>
import bulmaCarousel from './../../../../node_modules/bulma-carousel/dist/js/bulma-carousel.min.js'
export default {
    name:'Diapo',
      data() {
    return {
      imagesCarousel: [
        {
            id: '1',
            text: 'Stéphanie à la Ciotat recherche une place pour son voilier de 10m',
            thumb: require('./../../../assets/mugShots/mugshot-1.jpg')
        },
        {
            id: '2',
            text: 'Joana à Quimper cherche une place pour son Jeanneau SunFast ',
            thumb: require('./../../../assets/mugShots/mugshot-2.jpg')
        },
        {
            id: '3',
            text: 'Alexandre à Sanary recherche un bateau-stocker pour son Swift Trawler',
            thumb: require('./../../../assets/mugShots/mugshot-3.jpg')
        },
        {
            id: '4',
            text: 'Stéphanie à la Ciotat recherche une place pour son voilier de 10m',
            thumb: require('./../../../assets/mugShots/mugshot-4.jpg')
        },
        {
            id: '5',
            text: 'Jordane à Ollioules recherche une place pour son Zodiac',
            thumb: require('./../../../assets/mugShots/mugshot-5.jpg')
        },
        {
            id: '6',
            text: 'Henry à Mandelieu recherche une place pour son Benneteau',
            thumb: require('./../../../assets/mugShots/mugshot-6.jpg')
        },
        {
            id: '7',
            text: 'Francois à Nice recherche une place au port',
            thumb: require('./../../../assets/mugShots/mugshot-7.jpg')
        },
        {
            id: '8',
            text: 'Bernard cherche un bateau-stocker à Roskoff pour son Jeanneau',
            thumb: require('./../../../assets/mugShots/mugshot-8.jpg')
        },
        {
            id: '9',
            text: 'Kevin de Saint-Malo cherche une place pour son JetSki',
            thumb: require('./../../../assets/mugShots/mugshot-9.jpg')
        },
        {
            id: '10',
            text: 'André à Périgueux recherche un emplacement pour son SPACEDECK',
            thumb: require('./../../../assets/mugShots/mugshot-10.jpg')
        }
    ],
    }
  },
  mounted() {
    // Initialize all elements with carousel class.
    this.carousel = bulmaCarousel.attach('#carouselCTA', {
      initialSlide:0,
      slidesToScroll: 1,
      slidesToShow: 6,
      infinite: true,
      autoplay:true,
      pagination:false,
      navigationKeys:false,
      breakpoints:[
        {
           changePoint: 480, slidesToShow: 1, slidesToScroll: 1 
        },
        {
           changePoint: 640, slidesToShow: 2, slidesToScroll: 1 
        },
        /*{ 
          changePoint: 781, slidesToShow: 3, slidesToScroll: 3 
        },
        
        { 
          changePoint: 1087, slidesToShow: 3, slidesToScroll: 3 
        },*/
        { 
          changePoint: 1279, slidesToShow: 3, slidesToScroll: 1 
        },
        { 
          changePoint: 1471, slidesToShow: 4, slidesToScroll: 1 
        },
        { 
          changePoint: 2560, slidesToShow: 6, slidesToScroll: 1 
        }
        ]
    })
  }
}
</script>
<style scoped>
.information-text .heading-title {
  width: 100%;
  word-break: break-all;
  hyphens: auto;
  margin-top: 2rem;
  padding-bottom: 0rem;
}

@media screen and (min-width:320px) and (max-width:1049px) {
  .information-text .heading-title {
    font-size: 5vw;
    margin-top: 0rem;
  }
}
@media screen and (min-width:1050px) and (max-width:1920px) {
  .information-text .heading-title {
    line-height: 3.5rem;
    font-size: 3.5rem;
    margin-top: 0rem;
  }

}
@media only screen  and (min-width : 1921px) and (max-width:3000px) {
  .information-text .heading-title {
      font-size: 2.9vw;
      line-height: 2.9vw;
      margin-top: 0rem;
  }
}
.autoHome {
  margin-top:5vw;
  padding:0;
  margin-left:5vw;
  width: 70vh;
}
@media screen and (max-width: 980px) {
  .autoHome {
    margin-top:5vw;
    margin-right:5vw;
    width: initial;
  }
}
.diapCtaCarr,
.carousel,
.slider,
.slider-container {
  outline: none !important;
}
.carousel {
  margin-bottom: 2rem;
}
.slider-pagination .slider-page {
  background-color:#0dc0b6 !important
}
.diapCtaCarr {
  overflow:hidden;
  max-width:60vw;
  margin:0 auto 2rem
}
.card {
  height: 99%;
}
.card-content {
  padding:1rem;
  font-size:14px;
  margin-bottom: 40px;
}
div[class^=item] {
    height: 100%;
}
@media screen and (max-width: 1049px){
  .diapCtaCarr {
    overflow:hidden;
    max-width:100vw;
    
  }
}
.slider-container .slider-item{
  margin: 20px 5px !important;
}
</style>
