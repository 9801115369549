<template>
  <div class="form research-cta-place">
    <h2 class="is-size-4 is-size-6-mobile is-uppercase has-text-primary has-text-weight-regular
    ">Où voulez-vous stocker votre bateau?</h2>
    <form class="field" @submit.prevent="">
      <div class="field">
        <div class="control has-icons-right">
          <vue-google-autocomplete
            ref="google_maps_address"
            id="google_maps"
            classname="input"
            placeholder="Rechercher une adresse..."
            v-on:placechanged="getAddressData"
            types="(cities)"
            :country="country"
            :enable-geolocation="true"
          />
          <span class="icon is-small is-right">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
      <b-field>
        <p class="control button is-white has-text-primary">
          <span class="icon has-text-primary">
            <i class="fas fa-bullseye"></i>
          </span>
          <router-link :to="{name:'Research'}" push>Autour de moi</router-link>
        </p>
      </b-field>
      <router-link :to="localisation" class="is-primary button" :class="!isSearch?'disabled':''" :disabled="!isSearch" push>Rechercher</router-link>
    </form>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { SET_LOCALISATION } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'Place',
  components: { VueGoogleAutocomplete },

  data () {
    return {
      localisation: { name: 'Research' },
      isSearch: false,
      country: []//['FR', 'GP', 'MQ', 'GF', 'RE', 'YT', 'PM', 'BL', 'MF', 'WF', 'PF', 'NC']
    }
  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
      this.$store.dispatch(SET_LOCALISATION, addressData)
      this.isSearch = true
      this.localisation = { name: 'Researchcity', params: { city: addressData.locality.toLowerCase() } }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.research-cta-place input {
  border-radius: 0 !important;
}
.research-cta-place form{
  padding-top: 1rem;
}
.disabled {
  pointer-events: none;
}
</style>
