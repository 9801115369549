<template>
  <section class="diapBg">
    <Diapo v-if="!ie11"/>
    <div class="inverted-gradient-bg">
      <div class="has-text-centered has-text-text is-size-5">
        <div class="information-text">
          <h1 class="heading-title is-uppercase has-text-white has-text-weight-bold addMarginBottom-1">louer mon espace</h1>
          <Separator class="image is-48x48" color="white"/>
        </div>
        <div class="container">
          <div class="columns has-text-white">
            <div class="column addPadd">
              <div class="pseudoCta has-background-danger has-text-centered">
                <h3 class="has-text-weight-bold has-text-white is-uppercase is-size-4">Vous êtes un particulier:</h3>
                <strong class="has-text-white">Vous avez un jardin? un terrain? un garage? un entrepôt?</strong>
              </div>
            </div>
            <div class="column addPadd">
              <div class="pseudoCta has-background-primary has-text-centered">
                <h3 class="has-text-weight-bold has-text-white is-uppercase is-size-4">Vous êtes un professionnel:</h3>
                <strong class="has-text-white">Un port? un port à sec?</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="dbs container has-text-centered addMarginBottom-1">
          <div class="addPadd ">
            <div class="columns shadow is-vcentered is-multiline has-background-white">
              <div class="column is-12 addMarginBottom-1">
                <br>
                <h3 class="has-text-weight-bold has-text-danger is-uppercase is-size-4">Vous êtes un particulier:</h3>
                <small><em>Rien de plus facile avec jestockemonbateau !</em></small>
              </div>
              <div class="column is-5 addMarginBottom-1">
                <ul>
                  <li><span class="has-text-danger is-size-4">1.</span> Publiez votre annonce <strong class="is-uppercase has-text-primary">gratuitement</strong></li>
                  <li><span class="has-text-danger is-size-4">2.</span> Accueillez votre Bateau-skipper</li>
                  <li><span class="has-text-danger is-size-4">3.</span> Percevez votre loyer</li>
                </ul>
              </div>
              <div class="column is-2 addMarginBottom-1">
                <img class="hand" :src="require('./../../../assets/hand.png')" alt="JeStockeMonBateau.fr">
              </div>
              <div class="column is-5 has-text-text addMarginBottom-1">
                <h3 class="is-uppercase is-size-4">déposez votre annonce</h3>
                <h3 class="has-text-weight-bold is-uppercase is-size-4" style="margin-bottom:0.5rem">en 2 minutes !</h3>

                <router-link class="button is-danger is-small" :to="{name: 'Wizard'}" tag="a" v-on:click.native="submitAnnouncement($event)" push>Déposer mon annonce</router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="container">
        <div style="display:flex;justify-content:center;margin-top:4rem">

            <SocialDiapo/>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Diapo from "@/components/landing/landing-sections/Diapo.vue"
import Separator from "@/components/template/ui/Separator.vue"
import SocialDiapo from '@/components/template/ui/SocialDiapo.vue'
import { mapGetters } from 'vuex'
import store from '../../../store'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: "DiaporamaCTA",
  components: {
    Separator,
    SocialDiapo,
    Diapo
  },
  data () {
    return {
      ie11: false,
    }
  },
  methods: {
    submitAnnouncement: function (event) {
      if (!store.getters.isAuthenticated) {
        event.preventDefault()
        this.$store.dispatch(STATE_MODAL, {'show_modal': true})
      }else{
        this.$router.push('deposer-mon-annonce')
      }
    }
  },
  created() {
    // Vérifie le navigateur est IE
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.ie11 = true
    }
  },
  computed: {
    ...mapGetters(['modalState'])
  }
};
</script>
<style scoped lang="css">
  @import './../../../../node_modules/bulma-carousel/dist/css/bulma-carousel.min.css';
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.shadow {
  box-shadow:0px 6px 10px rgba(0,0,0,0.1);
  padding-bottom: 1rem;
}
.diapBg {
  background:#f2f5fb
}
.button.is-text.is-outlined {
  border-color: #0ec0b7 !important;
  color: #0ec0b7 !important;
}
li {
  font-weight: bold;
}
section {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.inverted-gradient-bg {
    padding-top: 2rem;
    padding-bottom: 4rem;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#f2f5fb), to(#0dc0b6));
    background-image: linear-gradient(to top, #f2f5fb, #0dc0b6);
    position: relative;
    overflow: hidden;
}
/* TEXT INFORMATIONS */
@media screen and (max-width: 1049px) {
  section {
    padding-top: 1rem;
  }

}
.pseudoCta {
  padding: 0.75rem
}
.information-text {
  width: 60%;
  margin: auto;
}

.information-text .heading-title {
  width: 100%;
  word-break: break-all;
  hyphens: auto;
  margin-top: 2rem;
  padding-bottom: 0rem;
}
.subheader {
  font-style: italic;
  letter-spacing: 0.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
.addMarginBottom {
  margin-bottom: 3rem;
}
.addMarginBottom-1 {
  margin-bottom: 1.5rem;
}
@media screen  and (max-width:768px) {
  .hand {
    transform: rotate(90deg)
  }
}
@media screen and (min-width:320px) and (max-width:1049px) {
  .information-text .heading-title {
    font-size: 5vw;
    margin-top: 0;
  }
  .subheader {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
  .information-text {
    width: 80%;
    margin: auto;
  }

}
@media screen and (min-width:1050px) and (max-width:1920px) {
  .information-text .heading-title {
    line-height: 3.5rem;
    font-size: 3.5rem
  }
  .subheader {
    font-size: 1.5rem;
  }
}
@media only screen  and (min-width : 1921px) and (max-width:3000px) {
  .information-text .heading-title {
      font-size: 2.9vw;
      line-height: 2.9vw;
  }
  .subheader {
    font-size: 1.5rem;
  }
}
.information-text .image {
  margin: auto;
}

.addPadd {
  padding: 0.25rem
}

</style>
