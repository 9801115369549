<template>
  <section>
    <div class="has-text-centered has-text-text is-size-5">
      <div class="information-text">
        <h1 class="heading-title is-uppercase has-text-primary has-text-weight-bold">JeStockeMonBateau</h1>
        <h2 class="subheader has-text-danger">La nouvelle solution pour stocker votre bateau !</h2>
        <Separator class="image is-48x48" color="text"/>
        <p>
          <strong>Jestockemonbateau est le spécialiste du stockage de bateaux chez les particuliers et professionnels !</strong>
          <br>
          La première plateforme dans le monde mettant en relation des propriétaires d'un jardin, d'un d'abri, d'un box<br class="hideSM"> ou d'un garage, avec des plaisanciers qui recherchent un
          endroit où stocker leur bateau.
        </p>
        <video controls preload="auto" poster="./../../../assets/video/thumbnail.jpg" style="margin-bottom:1rem">
          <source src="./../../../assets/video/jestockemonbateau.mp4" type="video/mp4">
          <source src="./../../../assets/video/jestockemonbateau.webm" type="video/webm">
          <source src="./../../../assets/video/jestockemonbateau.webm" type="video/ogv">
          <p>Votre navigateur ne prend pas en charge les vidéos HTML5.</p>
        </video>
        <p><span class="has-text-danger is-size-4 has-text-weight-bold">VOUS SOUHAITEZ HIVERNER VOTRE BATEAU ?</span></p>
        <p class="addMarginBottom-1">JeStockeMonBateau vous trouve votre Bateau-Stockeur en 3 clics !</p>
        <p>
          Choisissez le lieu où vous souhaitez stocker votre bateau, et nous
          vous proposons des milliers de bateaux-stockeurs
          <br>
          <strong>PROCHE DE CHEZ VOUS OU DE VOTRE PORT PRÉFÉRÉ !</strong>
        </p>
      </div>
      <div class="information-tile tile-container">
        <div class="tile-content has-text-text" v-for="(tile, index) in tiles" :key="'tile-'+index">
          <div class="top-content">
            <figure>
                <img :src="tile.picture" :alt="tile.pictureAlt" class="image is-96x96">
            </figure>
            <h2 class="has-text-weight-semibold is-uppercase">{{tile.title}}</h2>
          </div>
          <Separator class="image is-48x48" color="text"/>
          <p>
            {{tile.subtitle}}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Separator from "@/components/template/ui/Separator.vue";

export default {
  name: "Informations",
  components: {
    Separator
  },
  data() {
    return {
      tiles: [
        {
          picture: require('./../../../assets/storage.png'),
          pictureAlt: 'Différentes possibilités de stockage',
          title: 'Différentes possibilités de stockage',
          subtitle: 'Je stocke ma remorque + mon bateau. Je stocke uniquement ma remorque'
        },
        {
          picture: require('./../../../assets/key.png'),
          pictureAlt: 'JeStockeMonBateau - Aucun prélèvement avant emménagement !',
          title: 'Aucun prélèvement avant emménagement !',
          subtitle: 'Aucun prélèvement ne sera effectué avant emménagement chez votre Bateau-Stockeur'
        },
        {
          picture: require('./../../../assets/cancel.png'),
          pictureAlt: 'JeStockeMonBateau - Location encadrée par un contrat',
          title: 'UNE LOCATION ENCADRÉE PAR UN CONTRAT',
          subtitle: 'Bénéficiez d\'un contrat adapté pour une location en toute sérénité'
        },
        {
          picture: require('./../../../assets/picto_assurance.png'),
          pictureAlt: 'JeStockeMonBateau - Vos biens assurés',
          title: 'Vos biens assurés !',
          subtitle: 'Stockez votre bateau en toute tranquillité et sécurité avec notre partenaire assurance AXA !'
        }
      ]
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
section {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
/* TEXT INFORMATIONS */
@media screen and (max-width: 1049px) {
  section {
    padding-top: 1rem;
  }

}
@media screen and (max-width: 1264px) {
  .hideSM {
    display: none;
  }
}
.information-text {
  width: 60%;
  margin: auto;
}

.information-text .heading-title {
  width: 100%;
  word-break: break-all;
  hyphens: auto;
  margin-top: 4rem;
  padding-bottom: 0rem;
}
.subheader {
  font-style: italic;
  letter-spacing: 0.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
}
.addMarginBottom {
  margin-bottom: 3rem;
}
.addMarginBottom-1 {
  margin-bottom: 1.5rem;
}
@media screen and (min-width:320px) and (max-width:1049px) {
  .information-text .heading-title {
    font-size: 5vw;
  } 
  .subheader {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
  .information-text {
    width: 80%;
    margin: auto;
  }
  .information-tile {
    margin: 2rem auto 1rem !important;
}
  
}
@media screen and (min-width:1050px) and (max-width:1920px) {
  .information-text .heading-title {
    line-height: 3.5rem;
    font-size: 3.5rem
  } 
  .subheader {
    font-size: 1.5rem;
  }
}
@media only screen  and (min-width : 1921px) and (max-width:3000px) {
  .information-text .heading-title {
      font-size: 2.9vw;
      line-height: 2.9vw;
  } 
  .subheader {
    font-size: 1.5rem;
  }
}
.information-text .image {
  margin: auto;
}
.information-text :nth-child(0n + 3) {
  padding-bottom: 3rem;
}
.information-text :nth-child(0n + 4) {
  padding-bottom: 1rem;
}
.information-text .is-size-span {
  font-size: 0.8rem !important;
}

/* TILE */
.information-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  flex-wrap: wrap;
  width: 80%;
  margin: 5rem auto;
}
.information-tile .tile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18rem;
}
.information-tile .image {
  margin: 0 auto;
}
.information-tile .tile-content .top-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8rem;
}
.information-tile .tile-content .top-content figure {
  height: 4rem;
}
.information-tile .tile-content figure img {
  height: 100%;
}
.information-tile .tile-content .top-content h2 {
  height: 4rem;
}
</style>
