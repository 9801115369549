<template>
  <div>
    <section class="section grad">
      <div class="information-text has-text-centered">
        <h1 class="heading-title is-uppercase has-text-primary has-text-weight-bold addMarginBottom-1">Ils parlent de nous</h1>
        <Separator class="image is-48x48" color="primary"/>
      </div>
      <div id="pressHome" class="container" style="overflow:hidden">
        <div ref="thisCarousel" id="carouselCTA" class="carousel">
          <div style="margin:0 10px" :class="[`item-${(index + 1)}`]" v-for="(imageCarousel, index) in imagesCarousel" :key="'image-carousel-'+index">
            <div class="card noShadow">
              <div class="card-image">
                <figure class="image">
                  <router-link class="has-text-white" :to="{name:'Press'}" tag="a" push>
                    <img :src="imageCarousel.thumb" alt="JeStockeMonBateau.fr - Nos bateaux skippers">
                  </router-link>
                </figure>
              </div>
              <!-- <div class="card-content">
                <div class="content">
                  <em>{{ imageCarousel.text}}</em>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import bulmaCarousel from './../../../../node_modules/bulma-carousel/dist/js/bulma-carousel.min.js'
export default {
  name:'Press',
  data() {
    return {
      imagesCarousel: [
        {
          id: '1',
          thumb: require('./../../../assets/press/lci-logo.png')
        },
        {
          id: '2',
          thumb: require('./../../../assets/press/La-Tribune-logo.png')
        },
        {
          id: '3',
          thumb: require('./../../../assets/press/argusdubateau-logo.png')
        },
        {
          id: '4',
          thumb: require('./../../../assets/press/boatIndustry-logo.png')
        },
        {
          id: '6',
          thumb: require('./../../../assets/press/breizhInfo-logo.png')
        },
        {
          id: '6',
          thumb: require('./../../../assets/press/coteBrest-logo.png')
        },
        {
          id: '7',
          thumb: require('./../../../assets/press/laProvence-logo.png')
        },
        {
          id: '8',
          thumb: require('./../../../assets/press/lesEchos-logo.png')
        },
        {
          id: '9',
          thumb: require('./../../../assets/press/madeInMarseille-logo.png')
        },
        {
          id: '10',
          thumb: require('./../../../assets/press/voileetmoteur-logo.png')
        },
        {
          id: '11',
          thumb: require('./../../../assets/press/voilesetvoiliers-logo.png')
        },
        {
          id: '12',
          thumb: require('./../../../assets/press/provenceAzur-logo.png')
        },
        {
          id: '13',
          thumb: require('./../../../assets/press/RadioJM-logo.png')
        }
      ]
    }
  },
  mounted() {
    // Initialize all elements with carousel class.
    this.carousel = bulmaCarousel.attach('#carouselCTA', {
      initialSlide:0,
      navigation:false,
      slidesToScroll: 1,
      slidesToShow: 6,
      autoplaySpeed:2000,
      infinite: true,
      autoplay:true,
      pagination:false,
      navigationKeys:false,
      breakpoints:[
        {
           changePoint: 480, slidesToShow: 2, slidesToScroll: 1 
        },
        {
           changePoint: 640, slidesToShow: 3, slidesToScroll: 1 
        },
        /*{ 
          changePoint: 781, slidesToShow: 3, slidesToScroll: 3 
        },
        
        { 
          changePoint: 1087, slidesToShow: 3, slidesToScroll: 3 
        },*/
        { 
          changePoint: 1279, slidesToShow: 4, slidesToScroll: 1 
        },
        { 
          changePoint: 1471, slidesToShow: 4, slidesToScroll: 1 
        },
        { 
          changePoint: 2560, slidesToShow: 6, slidesToScroll: 1 
        }
      ]
    })
  }
}
</script>

<style scoped>
  .information-text .heading-title {
    width: 100%;
    word-break: break-all;
    hyphens: auto;
    margin-top: 4rem;
    padding-bottom: 0rem;
  }
  @media screen and (min-width:320px) and (max-width:1049px) {
    .information-text .heading-title {
      font-size: 5vw;
      margin-top: 0;
    }
  }
  @media screen and (min-width:1050px) and (max-width:1920px) {
    .information-text .heading-title {
      line-height: 3.5rem;
      font-size: 3.5rem;
      margin-top: 0;
    }
  }
  @media only screen  and (min-width : 1921px) and (max-width:3000px) {
    .information-text .heading-title {
      font-size: 2.9vw;
      line-height: 2.9vw;
      margin-top: 0;
    }
  }
  .autoHome {
    margin-top:5vw;
    padding:0;
    margin-left:5vw;
    width: 70vh;
  }
  @media screen and (max-width: 980px) {
    .autoHome {
      margin-top:5vw;
      margin-right:5vw;
      width: initial;
    }
  }
  .diapCtaCarr,
  .carousel,
  .slider,
  .slider-container {
    outline: none !important;
  }
  .carousel {
    margin-bottom: 2rem;
  }
  .slider-pagination .slider-page {
    background-color:#0dc0b6 !important
  }
  .diapCtaCarr {
    overflow:hidden;
    max-width:60vw;
    margin:0 auto 2rem
  }
  .card {
    height: 90%;
  }
  .card-content {
    padding:1rem;
    font-size:14px;
    margin-bottom: 40px;
  }
  div[class^=item] {
    height: 100%;
  }
  @media screen and (max-width: 1049px){
    .diapCtaCarr {
      overflow:hidden;
      max-width:100vw;
      
    }
  }
  .slider-container .slider-item{
    margin: 20px 5px !important;
  }
  /* #carouselCTA .slider-navigation-next,
  #carouselCTA .slider-navigation-previous {
    display: none !important;
  } */
  .card.noShadow {
    box-shadow: none !important
  }
</style>

<style  lang="css">
  #pressHome .slider-navigation-next,
  #pressHome .slider-navigation-previous {
    display: none !important;
  }
</style>
