<template>
  <div class="card block has-background-danger">
    <div class="content-box">
      <div class="has-text-centered">
        <h3 class="has-text-white has-margin-bottom-1 unused-space">Vous avez un <br class="is-hidden-tablet is-hidden-desktop">espace inutilisé ?</h3>
        <h2 class="is-font-size-cta is-uppercase has-text-weight-bold has-text-white has-margin-bottom-1">
          gagnez plus de<br class="is-hidden-tablet is-hidden-desktop"> 3 600</span> euros/an !
        </h2>
        <p class="desc"><em><strong class="is-uppercase">Arrondissez vos fins</strong> de mois en stockant un bateau ou plusieurs bateaux chez vous!</em></p>
      </div>
      
      <br>
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="columns is-vcentered has-margin-bottom">
            <div class="column is-5">
              <p class="is-size-5 is-uppercase has-text-white longueur">Surface de votre<br> emplacement :</p>
            </div>
            <div class="column is-7">
              <b-tooltip always :label="String(surface + ' m²')" position="is-right">
                <input class="slider"  v-model="surface" step="1" min="15" max="3000" type="range"/>
              </b-tooltip>
            </div>
          </div>
        </div>
        <div class="results has-text-white has-text-centered">
          <p class="simulText">Avec JeStockeMonBateau <strong>gagnez plus de :</strong></p>
          <span class="is-size-simulator has-text-weight-bold has-text-centered ">{{ this.earnings }}€<span class="month">/mois</span></span>
        </div>

        <!-- <div class="column is-12">
          <div class="columns is-vcentered">
            <div class="column is-5">
              <p class="is-size-6 is-uppercase has-text-white has-text-weight-bold">Code postal :</p>
            </div>
            <div class="column is-7">
              <div class="control has-text-right">
                <input class="input has-background-primary is-small" type="text" placeholder="Ex: 13000" v-model="zip">
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12 has-text-right simulator-form-submit">
          <button class="button is-small is-cta-white" @click="openLoading" type="submit">
            <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="true"></b-loading>Calculer</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { SIMULATOR_REQUEST } from '@/store/modules/landing/actions/landing.actions'
import { watchFile, watch } from 'fs';

export default {
  name: 'Simulator',
  data () {
    return {
      earnings: 393,
      surface: 100,
    }
  },
  watch: {
    surface () {
      this.earnings = Math.round( (this.surface * 393) / 100)
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .has-margin-bottom {
    margin-bottom: 2rem;
  }
  .has-margin-bottom-1 {
    margin-bottom: 1rem;
  }
  .desc {
    font-size: 13px;
  }
  .unused-space {
    font-size:25px;
  }
  .block {
    padding: 2rem 2.5rem;
    width: 40rem;
    line-height: 1.5rem;
  }
  .block em {
    display: block;
  }
  .is-font-size-cta {
    font-size:1.8rem;
    line-height:1.8rem;
  }
  .simulator-form {
    padding-top: 1.5rem;
  }
  .simulator-form .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .simulator-form .field *:not(:first-child) {
    padding-left: 0.5rem;
  }
  .simulator-form input[type="text"] {
    padding: 1rem 0.5rem;
    border: solid 1px rgba(255, 255, 255, 0.3);
    border-radius: 0;
    color: rgba(255, 255, 255, 0.5);
    /* max-width: 5rem; */
  }
  .simulator-form .simulator-form-submit{
    padding-top: 1rem;
  }
  .simulator-form .button {
    padding: 0rem 2rem;
    border-radius: .2rem;
  }
  .month {
    font-size: 1rem
  }
  .simulText {
    font-size:0.9rem;
    width: max-content;
  }
  @media screen and (max-width:768px){
    .results {
      display: inline-flex;
      flex-direction: column;
      align-items: center !important;
    } 
    .simulText {
      margin-bottom: 2rem;
    }
    /* .block {
      height: 24rem;
    } */
  }
  .b-tooltip {
    display: flex;
  }
  .slider {
    -webkit-appearance: none;
    width: 85%;
    height: 10px;
    border-radius: 5px;   
    background: #fff;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: 0;
  }
  .slider::-webkit-slider-thumb {
    min-height: 20px;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #df5051;
    border:1px solid #fff;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #df5051;
    cursor: pointer;
  }

  .slider::-ms-thumb {
    min-height:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #df5051;
    border:2px solid #fff;
    cursor: pointer;
  }
  .b-tooltip.is-right:after {
    left: calc(85% + 5px + 2px);
    top: 3px;
    background: transparent;
    box-shadow: none;
  }
  .b-tooltip.is-right.is-primary:before {
    left: calc(85% + 2px);
    top: 3px;
    border-right: 5px solid transparent;

  }
  .is-cta-white {
    background: #fff;
    border: none;
    color:#0ec0b6
  }
  @media only screen and (max-width:769px){
    .longueur {
      margin-bottom: 1rem;
    } 
  }
  .is-size-simulator {
    font-size: 4rem;
    margin-left: 1rem;
  }
  @media only screen and (max-width:569px){
    .is-size-simulator {
      font-size: 3rem;
      margin-left: 1rem;
    }
    .simulText {
      font-size: 0.8rem;
    }
  }
  .results {
    display:flex; 
    align-items:baseline;
    margin:0 auto;
    padding-top: 1rem;
  }
  .results > .columns {
    height:3rem;
    align-items: baseline;
  }
  .results strong {
    color:#fff
  }


</style>
