<template>
      <div class="illustrationGradient">
        <figure class="illustrHome">
          <img class="desk" style="width:100%" :src="require('./../../../assets/illustration-accueil.png')" alt="">
          <img class="mobile" style="width:100%" :src="require('./../../../assets/illustration-accueil-sm.png')" alt="">
          
        </figure>
      </div>
</template>
<script>

export default {
    name:'Illustration',
}

</script>
<style scoped>

.illustrationGradient {
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,1) 80%, rgba(242,245,251,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f5fb', GradientType=0 );
}
.mobile {
  display: none;
}
@media only screen and (max-width: 1049px){
  .illustrationGradient {
    padding-top: 8vh
  }
  .desk {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>
