<template>
  <form class="time-panel columns is-mobile is-multiline is-variable is-1 field" @submit.prevent="research">
    <div class="column is-6">
      <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Du</span>
      <b-datepicker 
        v-model="beginningDate"
        placeholder="Date de début"
        icon-pack="fas"
        icon="calendar-alt"
        required
        :first-day-of-week="1"
        :month-names="this.$store.state.datepickerLocale.monthNames"
        :day-names="this.$store.state.datepickerLocale.dayNames"
        :min-date="this.getTodayDate()"
        @input="enableDateEnd">
      </b-datepicker>
    </div>

    <div class="column is-6">
      <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Au</span>
      <b-datepicker
        v-model="endingDate"
        placeholder="Date de fin"
        icon-pack="fas"
        icon="calendar-alt"
        required
        :first-day-of-week="1"
        :month-names="this.$store.state.datepickerLocale.monthNames"
        :day-names="this.$store.state.datepickerLocale.dayNames"
        :min-date="this.minEndingDate"
        position=is-bottom-left
        :disabled="isDisabled">
      </b-datepicker>
    </div>
    <div class="column has-text-right" v-if="this.$route.name === 'Researchcity' || this.$route.name === 'Research'">
      <button class="refresh-search-button" type='submit'>Appliquer</button>
    </div>
    <div class="column is-12 has-text-centered" v-else>
      <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">&nbsp;</span>
      <button class="is-primary button" type='submit'>Rechercher</button>
    </div>
  </form>
</template>

<script>
import { GET_DATEPICKER_FR } from '@/store/modules/template/actions/template.actions'

export default{
  name: 'Cta-time',

  props: {
    beginDate: { type: String, default: null },
    endDate: { type: String, default: null },
  },
  data() {
    return {
      isDisabled: true,
      minEndingDate: null,
      beginningDate: null,
      endingDate: null,
    }
  },
  created() {
    this.$store.dispatch(GET_DATEPICKER_FR)
    if (this.$props.beginDate !== null) {
      this.beginningDate = new Date(this.$props.beginDate)
    }
    if (this.$props.endDate !== null) {
      this.endingDate = new Date(this.$props.endDate)
      this.isDisabled = false
    }
  },
  methods: {
    getTodayDate () {
      let date = new Date()
      date.setHours(0,0,0,0)
      return date
    },
    enableDateEnd (dateValue) {
      if (dateValue !== null) {
        this.isDisabled = false
        let date = new Date(dateValue)
        date.setHours(0,0,0,1)
        this.minEndingDate = date
      }else{
        this.isDisabled = true
        this.minEndingDate = null
      }
      this.endingDate = null
    },
    dateDiff (date1, date2) {
      var diff = {}                           // Initialisation du retour
      var tmp = date2 - date1;

      tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60;                    // Extraction du nombre de secondes

      tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
      diff.min = tmp % 60;                    // Extraction du nombre de minutes

      tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
      diff.hour = tmp % 24;                   // Extraction du nombre d'heures
      
      tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
      diff.day = tmp;
      
      return diff.day;
    },
    research () {
      let query = { ...this.$route.query, ...{ beginningDate: this.getDateFormat(this.beginningDate), endingDate: this.getDateFormat(this.endingDate) } }
      if (this.$route.name === 'Researchcity' || this.$route.name === 'Research') {
        this.$router.push({ name: this.$route.name, params: this.$route.params, query: query })
        this.$emit('update-time', query)
      }else{
        this.$router.push({ name: 'Research', query: query })
      }
      this.$parent.$parent.isActive = false
    },
    getDateFormat(date) {
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day;
      }
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month;
      }
      let year = date.getFullYear()
      return year+'-'+month+'-'+day
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .time-panel .field{
    padding: 1rem;
    margin: auto;
    width: 50%
  }
  .time-panel:first-child{
    padding: 0;
    margin: 0;
  }
  .time-panel .field-label{
    padding: 0;
    margin: 0;
  }
  .refresh-search-button {
    font-size: 0.7rem;
    color: #df5051;
    padding-right: 0.8rem;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
</style>
<style>
  /* .annonce-research-container .columns.is-mobile > .column.is-6 {
    width:100%;
  } */
</style>

