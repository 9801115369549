<template>
  <div class="social-wrap">
      <a class="button is-primary is-outlined social" :class="[color, size]" href="https://www.facebook.com/jestockemonbateau/" target="_blank"><i class="fab fa-facebook-f"></i></a>
      <a class="button is-primary is-outlined social tw" :class="[color, size]" href="https://twitter.com/Istockmyboat/" target="_blank"><i class="fab fa-twitter"></i></a>
      <a class="button is-primary is-outlined social" :class="[color, size]" href="https://www.instagram.com/jestockemonbateau/" target="_blank"><i class="fab fa-instagram"></i></a>
      <a class="button is-primary is-outlined social" :class="[color, size]" href="https://fr.linkedin.com/company/jestockemonbateau" target="_blank"><i class="fab fa-linkedin-in"></i></a>
  </div>
</template>

<script>
export default {
  name: 'SocialDiapo',
  props: {
    color: {
      type: String,
      default: 'is-text'
    },
    size: {
      type: String,
      default: 'is-medium'
    }
  }
}
</script>

<style scoped lang="css">
.social-wrap {
    display:flex;
    align-items: center;
}
.social-wrap a{
  margin: 0rem .25rem;
  border-radius: 2000px;
}
.social {
  text-decoration: none !important;
}
.is-small {
    padding: 0rem .35rem;
}
.is-small i{
    min-width: .9rem;
}
.is-medium {
    padding: 0rem .9rem;
}
.is-medium i{
    min-width: 0.85rem;
}
  .tw {
    padding:5px 11px
  }
</style>
