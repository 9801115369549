<template>
  <div class="research-cta has-background-white">
    <div class="box">
      <div class="tabs is-fullwidth" >
        <ul>
          <li :class="currentTab ==0 ? 'is-active' : ''" @click="currentTab = 0"><a>Lieu</a></li>
          <li :class="currentTab == 1 ? 'is-active' : ''" @click="currentTab = 1" ><a>Place</a></li>
          <li :class="currentTab == 2 ? 'is-active' : ''" @click="currentTab = 2"><a>Date</a></li>
        </ul>
      </div>
      <div class="tab-content">
        <Place class="tab-content" v-if="currentTab == 0"/>
        <Area class="tab-content" v-if="currentTab == 1"/>
        <Time class="tab-content overflow" v-if="currentTab == 2"/>
      </div>
    </div>
  </div>
</template>

<script>
import Place from '@/components/landing/landing-sections/research-cta/res-sections/Place.vue'
import Area from '@/components/landing/landing-sections/research-cta/res-sections/Area.vue'
import Time from '@/components/landing/landing-sections/research-cta/res-sections/Time.vue'
export default {
  name: 'Research',
  data () {
    return {
      currentTab: 0
    }
  },
  components: {
    Place,
    Area,
    Time
  },
  methods: {
    handleOverflow: function () {
      setTimeout(this.overflowState, 1000)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
/* Container */
.research-cta {
  width: 35rem;
  max-width: 35rem;
  border: 0;
  z-index: 1;
}
.research-cta .box {
  border-radius: 0;
  padding: 0rem;
}
.research-cta .tab-content {
  height: 100%;
  padding: 1rem 1rem 2rem 1rem;
}
/* Navigation */
.research-cta ul {
  border-bottom: 0;
}
  .research-cta ul li {
    height: 4rem;
    padding: 0;
    background-color: #f8faff;
    color: #bec6d2;
  }
  .research-cta ul li:hover a {
    color: #27c6bd;
  }
  .research-cta ul .is-active {
    background-color: #fff;
  }
    .research-cta ul .is-active span {
      color: #27c6bd;
    }
    .research-cta ul li a {
      padding-top: 1.5rem;
      border-bottom: 0;
      color: inherit;
    }
    .tabs:not(:last-child) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 768px){
      .research-cta .tab-content {
        padding: 0.5rem;
      } 
      .research-cta .tab-content .tab-content {
        padding: 0rem;
      } 
    }
</style>