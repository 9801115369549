<template>
  <div id="landing">
    <Main/>
    <Simulator :class="displayOnTouch ? 'simulator-mobile-position' : 'simulator-position is-pulled-right'"/>

    <Illustration />

    
    <Informations style="background-color: #f2f5fb"/>
    <Contract class="height"/>
    <!-- <Promotional/> -->
    <!-- <Contact class="height"/> -->
    <DiaporamaCTA/>
    <Press v-if="!ie11"/>
  </div>

</template>

<script>
import Main from '@/components/landing/landing-sections/Main.vue'
import Simulator from '@/components/landing/landing-sections/Simulator.vue'
import Informations from '@/components/landing/landing-sections/Informations.vue'
import Contract from '@/components/landing/landing-sections/Contract.vue'
import Press from '@/components/landing/landing-sections/Press.vue'
import Illustration from '@/components/landing/landing-sections/Illustration.vue'
// import Promotional from '@/components/landing/landing-sections/Promotional.vue'
// import Contact from '@/components/landing/landing-sections/Contact.vue'
import DiaporamaCTA from '@/components/landing/landing-sections/DiaporamaCTA.vue'
import { SIGNUP_CHECK_USER, FORGOTTEN_CHECK } from '@/store/modules/entrance/actions/entrance.actions'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'
import { setTimeout } from 'timers';

export default {
  name: 'Landing',
  components: {
    Main,
    Simulator,
    Press,
    Informations,
    Contract,
    Illustration,
    // Promotional,
    // Contact,
    DiaporamaCTA,
  },
  props: {
    validationToken: {type: String},
    passwordToken: {type: String}
  },
  metaInfo () {
    let siteName = this.$store.state.globalConstant.SITE_NAME,
      baseUrl = this.$store.state.globalConstant.BASE_URL

    let title = (siteName+' - Le spécialiste du stockage de bateau chez les particuliers et professionnels - Hivernage Bateau - Stockage bateau hors d\'eau'),
      description = (siteName+' est le spécialiste du stockage de bateaux chez les particuliers et professionnels avec une assurance ! La première plateforme dans le monde mettant en relation des propriétaires d\'un jardin, d\'un abri, d\'un box ou d\'un garage, avec des plaisanciers qui recherchent un endroit où stocker leur bateau.');

    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      titleTemplate: title,
      link: [
        { rel: 'canonical', href: baseUrl },
      ],
      meta: [
        { 
          vmid: 'description', 
          name: 'description', 
          content: description 
        },
        {
          'property': 'og:title',
          'content': title,
          'vmid': 'og:title'
        },
        {
          'property': 'og:description',
          'content': description,
          'vmid': 'og:description'
        },
        {
          'property': 'og:url',
          'content': baseUrl,
          'vmid': 'og:url'
        },
        {
          'property': 'og:image',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image'
        },
        {
          'property': 'og:image:secure_url',
          'content': (baseUrl+require('./../../assets/og-image.png')),
          'vmid': 'og:image:secure_url'
        },
        {
          'property': 'og:image:alt',
          'content': title,
          'vmid': 'og:image:alt'
        },
        {
          'name': 'twitter:title',
          'content': title,
          'vmid': 'twitter:title'
        },
        {
          'name': 'twitter:description',
          'content': description,
          'vmid': 'twitter:description'
        },
       {
          'name': 'twitter:image',
          'content': (baseUrl+require('./../../assets/tw-card.jpg')),
          'vmid': 'twitter:image'
        },
      ]
    }
  },
  created() {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.ie11 = true
    }
  },
  mounted () {
    if(typeof this.$props.validationToken !== 'undefined') {
      this.$store.dispatch(
        SIGNUP_CHECK_USER,
        {token: this.$props.validationToken}
      ).then(
        resp => {
          const {success, msg, data} = resp.data.response
          if (!success) {
            console.log(msg);
          }
        }
      )
    }else if (typeof this.$props.passwordToken !== 'undefined') {
      this.$store.dispatch(
        FORGOTTEN_CHECK,
        {token: this.$props.passwordToken}
      ).then(
        resp => {
          const {success, msg, data} = resp.data.response
          if (!success) {
            console.log(msg);
          }else{
            localStorage.setItem('forgottenPasswordToken', this.$props.passwordToken)
          }
        }
      )
    }
    // Affiche la modale si pas Auth
    setTimeout(() => {
      if (this.$store.state.showModal) {
        this.$store.dispatch(STATE_MODAL, {'show_modal': true, 'show_signup': false})
      }
    }, 1000);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  #landing {
    position: relative;
  }
  .simulator-position {
    z-index: 10;
    margin-top: -7vh;
    margin-right:5%;
  }
  .simulator-mobile-position {
    z-index: 10;
    margin-top: -5vh;
    margin: -5vh auto;
  }
  @media screen and (max-width: 640px) {
    .simulator-mobile-position {
      z-index: 10;
      margin: 0 auto;
    } 
  }
  .height {
    min-height: 100vh;
    background-color: #f2f5fb;
  }
</style>
