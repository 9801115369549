import { render, staticRenderFns } from "./Cta.vue?vue&type=template&id=0aa5d5d6&scoped=true&"
import script from "./Cta.vue?vue&type=script&lang=js&"
export * from "./Cta.vue?vue&type=script&lang=js&"
import style0 from "./Cta.vue?vue&type=style&index=0&id=0aa5d5d6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aa5d5d6",
  null
  
)

export default component.exports