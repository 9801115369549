<template>
  <section class="has-text-white">
    <div :class="displayOnTouch ? 'invertOrder' : 'columns'">

      <!-- Right content -->
      <div class="column  gradient-bg main-display" :class="displayOnTouch ? 'main-responsive-height' : 'main-height'">
        <div class="research-padding">
          <Research :class="displayOnTouch ? 'is-center' : 'is-pulled-right research-cta-margin'"/>
        </div>
        <Anim  :key="$mq"/>
        <div class="home-min-height" :class="displayOnTouch ? 'mobile-text-left-padding' : 'text-left-padding'">
          <Separator class="image is-48x48" color="white" />
          <h2 class="title has-text-white has-text-weight-bold">VOUS ÊTES UN <br/>BATEAU-SKIPPER</h2>
          <p class="is-size-5 ">
            Un bateau-skipper est un plaisancier recherchant un endroit où stocker son bateau.
          </p>
        </div>
      </div>

      <!-- Left content -->
      <div class="column  main-display picture-bg" :class="displayOnTouch ? 'main-responsive-height' : 'main-height'">
        <div class="layer">
        </div>
        <div class="over-layer">
          <Cta/>
        </div>
        <div class="home-min-height over-layer" :class="displayOnTouch ? 'mobile-text-left-padding' : 'text-left-padding'">
          <Separator class="image is-48x48" color="white" />
          <h2 class="title has-text-white has-text-weight-bold">DEVENEZ <br/>BATEAU-STOCKEUR</h2>
          <p class="is-size-5 txt-stocker">
            Un bateau-stockeur est un particulier possédant des mètres
            carrés inutilisés dans sa propriété, comme un terrain, un garage,
            un entrepôt... souhaitant compléter ses revenus en louant son
            emplacement pour hiverner un bateau.
          </p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Separator from '@/components/template/ui/Separator.vue'
import Research from '@/components/landing/landing-sections/research-cta/ResearchCta.vue'
import Anim from '@/components/landing/landing-sections/anim.vue'
import Cta from '@/components/landing/landing-sections/cta/Cta.vue'

export default {
  name: 'Main',
  
  components: {
    Research,
    Cta,
    Separator,
    Anim
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.main-display {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5rem;
}
.research-cta-margin {
  margin-left: 6rem;
}
.research-padding {
  position: relative;
  z-index: 20;
}
/* .main-position-bottom {
  position: absolute;
  bottom:5rem;
} */
@media screen and (max-width: 1049px) {
  .invertOrder {
    display:flex;
    flex-direction: column
  }
  .main-display {
    order:1
  }
  .gradient-bg {
    order:2
  }
}
@media screen and (min-width:568px) and (max-width:1190px) {
  .title {
    line-height: 2.8rem;
    font-size: 2.8rem
  }
  .txt-stocker {
    width: 100%
  }
}
@media screen and (min-width:1191px) and (max-width:1920px) {
  .title {
    line-height: 3.3rem;
    font-size: 3.3rem
  }
  .txt-stocker {
    width: 100%
  }
}
@media only screen  and (min-width : 1921px) and (max-width:3000px) {
  .title {
    line-height: 3.5rem;
    font-size: 3.5rem
  }
  .txt-stocker {
    width: 70%
  }
}
/* .title {
  line-height: 2.7rem;
  font-size: 3rem
} */
.layer {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
  .over-layer {
    z-index: 1;
  }
  .text-left-padding {
    padding-right: 8rem;
    padding-left: 6rem;
  }
  @media only screen and (max-width:1598px){
    .text-left-padding {
      padding-right: 3rem;
      padding-left: 3rem;
    }
    /*.title {
      line-height: 2.2rem;
      font-size: 2.2rem;
    }*/
  }
  .mobile-text-left-padding {
    padding-top: 5rem;
    margin:auto;
    width: 85%;
  }
.is-center {
  margin:auto;
  width: 85%;
  margin-top: 3rem;
}
.gradient-bg {
  background-image: linear-gradient(to top, #0dc0b6, #f2f5fb);
  position: relative;
  overflow: hidden;
}
.picture-bg {
  position: relative;
  background-image: url("./../../../assets/main.jpg"); /* The image used */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.main-height {
  min-height: 89vh;
  justify-content: space-between;
}
.main-responsive-height {
  min-height: fit-content;
    justify-content: space-between;

}

.home-min-height {
  min-height: 350px;
  margin-top: 4vw;
}

@media only screen and (max-width: 1049px){
  .home-min-height {
    min-height: 300px;
    margin-top: 0;
    margin-bottom: 2vw;
  }

}
</style>
