<template>
  <div class="">
    <div class="columns has-text-centered ">
      <section class="column">
        <div :class="displayOnTouch ? '' : 'columns'">
          <div class="column contract-picture-bg">
          </div>
          <div class="column">
            <div class="text-content is-vcentered">
              <h3 class="is-size-4 has-text-text has-text-weight-semibold is-uppercase">
                Bénéficiez d'un contrat adapté
                pour encadrer la location
              </h3>
              <div class="control has-text-centered">
              <router-link v-if="this.$store.getters.isAuthenticated" class="control is-primary button" :to="{name:'Howitworks'}" tag="button" push>
                Comment ça marche ?
              </router-link>
              <button v-else class="control is-danger button" @click="openModal">
                Créez votre compte
              </button>
            </div>

            </div>
          </div>
        </div>
        <div :class="displayOnTouch ? 'reverse-columns' : 'columns'">
          <div class="column">
            <div class="text-content is-vcentered">
              <h3 class="is-size-4 has-text-text has-text-weight-bold is-uppercase">
                Stockez votre bateau ou votre
                remorque en fonction de vos
                envies
              </h3>
              <div class="control has-text-centered">
              <router-link class="control is-danger button" :to="{name: 'Research'}" tag="button" push>
                Trouvez votre bateau stockeur
              </router-link>
              </div>
            </div>
          </div>
          <div class="column earning-picture-bg">
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'Contract',
  mounted() {
    var background = document.querySelector(".contract-picture-bg"),
        background2 = document.querySelector(".earning-picture-bg");

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          window.addEventListener('scroll', () => {
            var coords = 'right 0px',
              coords2 = 'left 0px';
              coords = 'right '+ ( - (background.getBoundingClientRect().top*0.2) + 'px');
              coords2 = 'left '+ ( - (background2.getBoundingClientRect().top*0.2) + 'px');
            background.style.backgroundPosition  = coords;
            background2.style.backgroundPosition  = coords2;
          });
        }
      });
    });
    observer.observe(document.querySelector('.contract-picture-bg'));
  },
  methods: {
    openModal: function () {
      this.$store.dispatch(STATE_MODAL, {'show_modal': true, 'show_signup': true})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.columns:not(:last-child) {
    margin-bottom: 0rem !important;
}
.column{
  min-width: 50%;
}
.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
  max-width: 25rem;
  margin: auto;
  padding-bottom: 2rem;
  line-height: 1.5rem;
}
  .text-content .control{
    margin: 1rem auto;
  }
.reverse-columns {
  display: flex;
  flex-direction: column-reverse;
}
.reverse-columns .column{
  flex-basis: auto;
}
.contract-picture-bg {
  height: 50vh;
  background-image: url("./../../../assets/contract.jpg");
  background-repeat: no-repeat;
  background-position: right 0;
  background-size: cover;
}

.earning-picture-bg {
  height: 50vh;
  background-image: url("./../../../assets/earning.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width:320px) and (max-width:1049px) and (orientation: portrait) {
  .earning-picture-bg,
  .contract-picture-bg {
    background-size:contain;
    height: 40vh;
    background-position: center bottom;
  }
  .text-content {
      height: 30vh;
      padding: 0;
    }
}
@media screen and (min-width:320px) and (max-width:1049px) and (orientation: landscape) {
  .earning-picture-bg,
  .contract-picture-bg {
    background-size:cover;
    height: 70vh;
    background-position: center bottom;
  }
  .text-content {
    height: 30vh;
    padding: 0;
  }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) { 
    .earning-picture-bg,
    .contract-picture-bg {
      background-size:cover;
  }
 }

@media screen and (min-width:1050px) and (max-width:1920px) {

}
@media only screen  and (min-width : 1921px) and (max-width:3000px) {

}
</style>
