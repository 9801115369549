<template>
  
  <div @click="submitAnnouncement($event)" class="has-background-danger has-text-white " :class="displayOnTouch ? 'mobile-cta-container' : 'cta-container'">
    <div class="cta is-vcentered">
      <p class="has-text-weight-bold is-size-4 is-size-6-mobile">DÉPOSER MON ANNONCE</p>
      <span class="icon">
        <i class="fas fa-chevron-right"></i>
      </span>
    </div>
    <p class="is-size-7">DÉPOSEZ VOTRE ANNONCE <strong>GRATUITEMENT !</strong></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../../store'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'Cta',
  methods: {
    submitAnnouncement: function (event) {
      if (!store.getters.isAuthenticated) {
        event.preventDefault()
        this.$store.dispatch(STATE_MODAL, {'show_modal': true})
      }else{
        this.$router.push('deposer-mon-annonce')
      }
    }
  },
  computed: {
    ...mapGetters(['modalState'])
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .cta-container{
    cursor: pointer;
    padding: 2.5rem 2.5rem;
    width: 25rem;
    position: absolute;
    top:0
  }
  .mobile-cta-container{
    cursor: pointer;
    padding: 1.8rem 1.8rem;
    margin: 5rem 2rem 0 2rem;
    background-color: rgb(223, 80, 81);
    transition: ease 0.4s;
    width: auto;
  }
  
  .cta {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
  }
  .cta span {
    padding-left: 2.5rem;
  }
  p:last-child {
    padding-top: 0.5rem;
  }
  strong {
    color: #fff;
  }
</style>
